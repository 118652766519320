/* eslint-disable*/
require("dotenv").config();
const API_URL = process.env.APIURL
console.log(API_URL)
const VueVueStoreService = {
    async get(url, store) {
        console.log('urlll',url)
        var request= {
            headers:this.getHeaders(),
            method: "GET",
            url : API_URL+ url,
        }
        const resp = await store.dispatch("back/EXECUTE",request)
        return resp;
    }, 
    async delete(url,store){
        var request = {
            headers:this.getHeaders(),
            method: "DELETE",
            url : API_URL+ url,
        }
        const resp = await store.dispatch("back/EXECUTE",request)
        return resp;
    }, 
    async deleteWithData(url,data,store){
        var request = {
            headers:this.getHeaders(),
            method: "DELETE",
            url : API_URL+ url,
            data: data
        }
        const resp = await store.dispatch("back/EXECUTE",request)
        return resp;
    }, 
    async post(url,data,store){
       
        var request = {
            headers:this.getHeaders(),
            method: "POST",
            url : API_URL+ url,
            data:data
        }       
        const resp = await store.dispatch("back/EXECUTE",request)
        return resp;
    }, 
    async put(url,data,store){
        var request = {
            headers:this.getHeaders(),
            method: "PUT",
            url : API_URL+ url,
            data:data
        }
        const resp = await store.dispatch("back/EXECUTE",request)
        return resp;
    },  
    getHeaders(){
        return  {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }
    },
}
export default VueVueStoreService;