/* eslint-disable*/
import VueStoreService from './VueStoreService';
const ProfileService = {

    async updateData(user_id,data,store){
        var resp = await VueStoreService.put("api/user/profile/"+user_id,data,store)
        return resp;
    },

    async deleteUser(user_id,store){
        var resp = await VueStoreService.delete("api/user/profile/"+user_id,store)
        return resp;
    },
    async changePassword(user_id,data,store){
        var resp = await VueStoreService.put("api/user/password/"+user_id,data,store)
        return resp;
    },
}

export default ProfileService;